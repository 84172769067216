<label>{{label}}</label>
<div appFileSelect
     #uploadElement
     [class.dragging-target-active]="draggingOverTarget"
     class="d-flex flex-wrap"
     (dragging)="dragging($event)"
     (onFileDropped)="onFileChange($event)"
     [style.min-height]="sizeMap[size]"
     [attr.required]="required ?? null"
     [attr.uploaded]="!!isUploaded"
     [class.error]="!!error"
     [class.disabled]="isUploaded">
    <label class="d-flex align-items-center mt-2"
           [class.justify-content-center]="!(selectedFiles.length) || draggingOverTarget || draggingOverWindow || isUploaded"
           [for]="uniqueId"
           style="flex-basis: 100%">
        <div class="d-flex align-items-center px-4 py-2 bg-white rounded"
             *ngIf="(selectedFiles.length && isUploaded) || !draggingOverTarget && !draggingOverWindow"
             [appUnderProcessing]="uploadIsInProgress"
             [class.bg-white]="!isUploaded"
             [class.w-100]="selectedFiles.length || isUploaded">
            <fa *ngIf="!uploadIsInProgress"
                class="icon mr-1"
                [class.text-primary]="!isUploaded"
                [class.text-danger]="error"
                [i]="isUploaded ? 'check-circle' : 'file-alt'"
                [variant]="selectedFiles.length ? 'duotone' : 'light'">
            </fa>
            <div [class.ml-1]="uploadIsInProgress">
                <div *ngFor="let file of selectedFiles; let i = index">
                    <strong>{{file?.name}}</strong>
                    <span *ngIf="!isUploaded"
                          id="removeFileButton|{{i}}"
                          class="text-danger cursor-pointer ml-2">×
                    </span>
                </div>
            </div>
            <span *ngIf="!selectedFiles.length" class="btn btn-outline-primary ml-2 mr-2">Select file</span>
            <ng-container *ngIf="!selectedFiles.length">
                or drop file here.<span *ngIf="required" class="text-primary ml-1"> *</span>
            </ng-container>
            <span *ngIf="selectedFiles.length && !isUploaded" class="btn btn-sm ml-auto btn-outline-{{error ? 'danger' : 'primary'}}">Select file</span>
        </div>
        <div *ngIf="(draggingOverTarget || draggingOverWindow) && !isUploaded">
            <fad [class.text-primary]="!draggingOverTarget" i="file-alt"></fad>
            <strong>Drop Your File Here</strong>
        </div>
    </label>
    <input [disabled]="isUploaded"
           type="file"
           name="uploadFileInput"
           [id]="uniqueId"
           [multiple]="multiple"
           (change)="onFileChange($event)">
</div>
<div class="d-flex justify-content-end align-items-center" *ngIf="needUploadButton">
    <button [disabled]="!selectedFiles.length || isUploaded" class="btn btn-primary mt-2" (click)="onUpload()">Upload</button>
</div>
