import {Directive, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
    selector: '[appFileSelect]',
})

export class FileSelectDirective {
    @Output() onFileDropped = new EventEmitter<FileList>();
    @Output() dragging = new EventEmitter<string>();

    @HostListener('dragover', ['$event']) onDragOver(event) {
        if (this.getUploadedAttr(event)) return;
        event.preventDefault();
        event.stopPropagation();
        this.dragging.emit(event.currentTarget.firstElementChild.htmlFor);
    }

    @HostListener('dragenter', ['$event']) onDragEnter(event) {
        if (this.getUploadedAttr(event)) return;
        this.enterTarget = event.target;
        event.preventDefault();
        event.stopPropagation();
        this.dragging.emit(event.currentTarget.firstElementChild.htmlFor);
    }

    @HostListener('dragleave', ['$event']) onDragLeave(event: DragEvent) {
        if (this.getUploadedAttr(event)) return;
        if (this.enterTarget === event.target) {
            event.preventDefault();
            event.stopPropagation();
            this.dragging.emit(null);
        }
    }

    @HostListener('drop', ['$event']) onDragDrop(event: DragEvent) {
        if (this.getUploadedAttr(event)) return;
        event.preventDefault();
        event.stopPropagation();
        const file = event.dataTransfer.files;
        if (file.length) {
            this.onFileDropped.emit(file);
            this.dragging.emit(null);
        }
    }

    enterTarget;

    getUploadedAttr(event): boolean {
        return (event.currentTarget.attributes.getNamedItem('uploaded').value == 'true');
    }
}
