import {
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    HostBinding,
    HostListener,
    Input,
    Output,
    Renderer2,
    ViewChild,
} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {PATIENT_DOCUMENT_UPLOAD_CATEGORIES} from '../../../../@core/patient_document_upload_categories';


import {SlidePanelService} from '../../../slide-panel/slide-panel.service';
import {
    PatientDocumentType,
} from '../../../@slide-panel-preview/patient-details/patient-documents/patient-documents-permissions';


export interface DragAndDropOptions {
    patientId: number;
    documentSubType?: keyof typeof PATIENT_DOCUMENT_UPLOAD_CATEGORIES;
    description?: string;
    slidePanelTitle?: string;
    initialDocumentType?: PatientDocumentType;
}

@Component({
    selector: 'app-file-select',
    templateUrl: './file-select.component.html',
    styleUrls: ['./file-select.component.scss'],

    providers: [
        {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FileSelectComponent), multi: true},
    ],
})
export class FileSelectComponent {
    uniqueId: string;

    /* state */

    @Input() uploadIsInProgress = false;
    @Input() isUploaded = false;
    @Input() error: string;
    @Input() label: string;

    /* config */

    @Input() multiple: boolean;
    @Input() needUploadButton = true;
    @Input() size: 'full' | 'lg' | 'sm' = 'lg';
    @Input() required = false;
    @Input() submitCaption = 'Upload';

    /* data binding */

    @Input() selectedFiles: File[] = [];
    @Input() dragAndDropOptions: DragAndDropOptions;
    @Output() selectedFilesChange = new EventEmitter<File[]>();
    @Output() upload = new EventEmitter<File[]>();
    @Output() onDraggingOverWindow = new EventEmitter<boolean>();

    /* view queries */

    @ViewChild('uploadElement') uploadElement: ElementRef;

    /* members */

    draggingOverTarget: string = null;
    draggingOverWindow = false;
    enterTarget: EventTarget;

    sizeMap: {[key: string]: string} = {
        'full': '100%',
        'lg': '70px',
        'sm': '50px',
    };

    onTouched: (event?: Event) => any = () => {};

    constructor(private renderer: Renderer2, private slidePanel: SlidePanelService) {
        this.uniqueId = `file-select-${Math.random().toString(36)}`;
    }

    /* host configuration */

    @HostListener('window:dragover', ['$event']) onDragOver(event: DragEvent) {
        this.onDrag(event);
    }

    @HostListener('window:dragenter', ['$event']) onDragEnter(event: DragEvent) {
        this.enterTarget = event.target;
        this.onDrag(event);
    }

    @HostListener('focusout', ['$event.target.value']) onClick() {
        this.onTouched();
    }

    @HostListener('click', ['$event']) removeFile(event: Event) {
        if ((event.target as HTMLElement).id?.split('|')[0] === 'removeFileButton') {
            event.preventDefault();
            event.stopPropagation();
            const index = parseInt((event.target as HTMLElement).id.split('|')[1]);
            this.selectedFiles.splice(index, 1);
            this.resetFileInput();
            this.selectedFilesChange.emit(this.selectedFiles);
        }
    }

    @HostListener('window:dragleave', ['$event']) onDragLeave(event: DragEvent) {
        if (event.target == this.enterTarget) {
            event.preventDefault();
            event.stopPropagation();
            if (!this.draggingOverTarget && this.draggingOverWindow) {
                if (this.getIsUploadedAttr(this.uploadElement.nativeElement)) return;
                this.renderer.removeClass(this.uploadElement.nativeElement, 'dragging-over-window');
                this.draggingOverWindow = false;
                this.onDraggingOverWindow.emit(false);
            }
        }
    }

    @HostBinding('class.dragging-over-window') get isDraggingOverWindow() {
        return this.draggingOverWindow;
    }

    /* ControlValueAccessor */

    registerOnChange(fn: any): void {
        this.selectedFilesChange.subscribe(selectedFile => fn(selectedFile));
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(selectedFile: File[]): void {
        if (selectedFile !== null && !Array.isArray(selectedFile)) throw new Error('FileSelectComponent: writeValue() expects an array of files. If you use it as a form control, make sure to initialize the field value to an array, because form fields are often initialized to an empty string.');
        this.selectedFiles = selectedFile;
    }

    /* methods */

    resetFileInput() {
        const fileInput = this.uploadElement.nativeElement.querySelector('input[type="file"]');
        if (fileInput) {
            fileInput.value = '';
        }
    }

    onFileChange(file: FileList | Event | File) {
        this.renderer.removeClass(this.uploadElement.nativeElement, 'dragging-over-window');
        this.error = null;
        this.draggingOverWindow = false;
        this.onDraggingOverWindow.emit(false);
        if (file instanceof FileList) {
            const files = Array.from(file);
            this.selectedFiles = this.multiple ? [...this.selectedFiles, ...files] : (files.length > 1 ? [files[0]] : files);
        } else if (file instanceof Event) {
            const files = Array.from((file.target as HTMLInputElement).files);
            this.selectedFiles = this.multiple ? [...this.selectedFiles, ...files] : files;
        }
        if (this.dragAndDropOptions) {
            this.slidePanel.addComponent('PATIENT_DOCUMENT', null, null, null, {
                title: `${this.dragAndDropOptions.slidePanelTitle ?? 'Upload Patient\'s Document'}`,
                componentInputs: {
                    patientId: this.dragAndDropOptions.patientId,
                    document: this.selectedFiles,
                    initialDocumentType: this.dragAndDropOptions.initialDocumentType,
                    documentSubType: this.dragAndDropOptions.documentSubType,
                    description: this.dragAndDropOptions.description,
                },
            });
        }
        this.selectedFilesChange.emit(this.selectedFiles);
    }

    onUpload() {
        this.upload.emit(this.selectedFiles);
    }

    dragging(event) {
        this.draggingOverTarget = event;
    }

    getIsUploadedAttr(attr: ElementRef): boolean {
        return attr.nativeElement?.attributes.getNamedItem('uploaded').value == 'true';
    }

    private onDrag(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
        if (this.getIsUploadedAttr(this.uploadElement.nativeElement)) return;
        this.renderer.addClass(this.uploadElement.nativeElement, 'dragging-over-window');
        this.draggingOverWindow = true;
        this.onDraggingOverWindow.emit(true);
        this.onTouched(event);
    }
}
